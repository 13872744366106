function registerWidgets(scope) {
    $.brabo.register(scope);
    scope.find('a[confirm]').on('click', function(e) {
        return confirm($(this).attr('confirm'));
    });
    scope.find('form').each(function () {
        Nette.initForm(this);
    });
    $.nette.load(scope);
    registerScopeJs(scope);
}

$.nette.ext('js_widgets', {
    success: function(payload) {
        for (var snippet in payload.snippets) {
            registerWidgets($('#' + snippet));
        }
    }
});

$(function () {
    $.nette.init();
    registerWidgets($('body'));
});